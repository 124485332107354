/* You can add global styles to this file, and also import other style files */
@import "./assets/scss/app.scss";
@import "@fontsource/cairo"; // Defaults to weight 400.
@import '~animate.css/animate.min.css';

body   {
    font-family: "Cairo" !important;
    padding-right: 0px !important;
  }
.router-wrapper  {
    position:  relative;
    overflow:  hidden;
    width:  100vw;
    height:  calc(100%  -  47px);
    perspective:  1200px;
    transform-style:  preserve-3d;
}

:host  {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #60d7a9;
}

.ngSelectMin-heigh{
    min-height: 38px !important;
}
.ng-select.ng-select-single .ng-select-container {
    height: 40px !important;
}
.card .card-body .row .btn{
 direction: ltr;
}
.table th, .table td {
    padding: 0 0.5em !important;
    vertical-align: baseline;
    border-bottom: 1px solid #ebebeb !important;
  }
  .table th {
    background: #1b2651 !important;
    color: white !important;
    font-weight: 500;
    padding: 0.75rem !important;
    font-size: 15px;
  }
.btn-alignment{
  flex-direction: row-reverse;
}
.imgfromUrl{
  width: 25%;
  position: absolute;
  max-height: 100%;
  border-radius: 5px;
  opacity: .8;
  max-height: 80%;
  margin: -18px;
}

.mrScoolBtn{
  background: #1b2651 !important;
    border: none !important;
}
.w3{
  width: 3% !important;
}

.switch input:checked + .switch-state{
  background-color: #1b2651 !important;
}